@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-lightbg dark:bg-darkbg text-lightMajor dark:text-darkMajor;
  --primary-color: #0d90f3;
}

input[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
  @apply appearance-none outline-none w-4 h-4 rounded bg-white transition-all duration-300 checked:bg-primary;
}

.check-overlay {
  @apply relative;
}

.check-overlay::after {
  @apply bg-center bg-no-repeat z-10 w-full h-full absolute top-0 left-0;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}

::-webkit-scrollbar {
  @apply w-[1px] h-[10px];
}
::-webkit-scrollbar-track {
  @apply bg-transparent;
}
::-webkit-scrollbar-thumb {
  @apply bg-gray-200 rounded-2xl dark:bg-[#666] border-[#666] dark:border-[#191a1f];
}
::-webkit-scrollbar-thumb:hover {
  @apply bg-[#777];
}
::-webkit-scrollbar-button {
  @apply hidden;
}

* {
	-webkit-user-select: none; /* prevent select text in webview while long pressing */
}